import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"
import ScrollAnimation from 'react-animate-on-scroll';

import ImageHarcelement from '../components/images/imageHarcelement.js'
import ImageConflit2 from '../components/images/imageConflit2.js'

const Climat = () => (
  <Layout>
    <SEO title="Dénouer les tensions et conflits | Gestion Mieux-Être" />
    <PageHeader
        header="Dénouer les tensions et conflits"
        text="Les accrochages et affrontements ont de sérieuses incidences pour tous.  Oui, la résolution positive de ces luttes et oppositions est possible.  Tous en sortent ainsi gagnants!  "
        quote="C’est l’ignorance, et non la connaissance, qui dresse les hommes les uns contre les autres
            <span>- Kofi Annan</span>"
        background="tensions"
         />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">
        <p class="service-presentation">Nous vous proposons une formule de résolution basée sur un modèle collaboratif.</p>

        </ScrollAnimation>
        <br /><br />
        <div className="flex-container2">
            <ScrollAnimation
                delay="10"
                animateIn="fadeIn"
                className="half">
            <div>
                <Link to="tensions-et-conflits/politique-de-harcelement-psychologique/">

                    <ImageHarcelement />
                    <h6>Réactualiser votre politique de prévention du harcèlement psychologique</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="20"
                animateIn="fadeIn"
                className="half">
            <div>
                <Link to="tensions-et-conflits/denouer-les-relations-tendues/">
                    <ImageConflit2 />
                    <h6>Dénouer des relations tendues</h6>
                </Link>
            </div>
            </ScrollAnimation>
        </div>
        <br /><br /><br />
        <ScrollAnimation
            delay="10"
            animateIn="fadeIn">
        <Link className="button-link" to="nous-joindre">Nous joindre</Link>
        </ScrollAnimation>
        <br /><br />
    </div>
  </Layout>
)

export default Climat
